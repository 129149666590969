body {
  font-family: "Poppins", sans-serif !important;
}

/* li {
    list-style: none;
  } */
  
  a {
    text-decoration: none;
  }

 

/* hardware product start */

  .our_pro {
    max-width: 482px;
    width: 100;
    margin: 0 auto;
    padding-bottom: 68px;
  }
  .image1 {
    position: relative;
    margin-bottom: 30px;
  }
  
  .image1 span a {
    display: block;
    text-decoration: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
  
    color: white;
  }
  .image1:hover span {
    opacity: 1;
  }

  /* Copy code */
  /* Default styles */
  .our_pro {
    text-align: center; /* Center align the content */
  }
  
  .our_pro h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    padding-bottom: 15px;
    position: relative;
  }
  
  .our_pro h2::after {
    position: absolute;
    content: "";
    border: 1px solid #faa61a;
    max-width: 207px;
    width: 100%;
    bottom: 0;
    left: 50%; /* Position the border in the center horizontally */
    transform: translateX(-50%); /* Adjust for center alignment */
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .our_pro h2::after {
      left: 50%; /* Realign the border to the center */
      transform: translateX(-50%); /* Adjust for center alignment */
      bottom: -5px; /* Adjust the bottom position to move it slightly below the text */
    }
  }
  .hold_image {
    gap: 31px;
    justify-content: center;
    margin-top: 30px;
  }
  .our_product {
    padding: 50px 0px;
    position: relative;
  }
  

.image1 {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.tittlename {
    text-align: center;
    width: 100%;
    display: inherit;
    padding: 15px 0px;
    background: #faa61a;
    color: #000000;
    /* font-size: 25px; */
    text-transform: capitalize;
    font-weight: 600;
  }
  
  .product-category.product img {
  padding-top: 30px;
  
  }
  
  .image1 img {
    /* position: relative;   */
    height: 300px;
  }
  
  .image1::after{
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #faa61a;
    transition: 0.3s ease-in;
    bottom: 0;
    left: 0;
  }
  
  .image1:hover:after {
    width: 100%;
  }
  

/* hardware product end */


/* whatsapp start */
.whatsap{
  z-index: 1000;
  position: absolute;
  bottom: 0;
  right: 0;
  /* top: 0; */
}
.whatsap img{
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 50px;
  
}

/* slider start */
.slideImg{
position: relative;
width: 100%;
height: 100%;
}



.content{
position: absolute;
top: 30%;
left: 0;
z-index: 2;
text-align: center;

}

.imgshodow{
 width: 100%;
 
}
.slideImg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(5, 5, 5, 0.436); /* Change the opacity as needed */
  width: 100%;
  height: 100%;
}
.text_bg{
  color: white;
  text-align: center;
  font-weight: 900;
  /* width: 50%; */
}

@media only screen and (max-width: 770px) {
  .content h1{
   font-size: 16px;
  }
  .content h5{
    font-size: 10px;
    margin-top: 10px !important;
  }

}

@media only screen and (max-width: 770px) {
  .content h1{
    font-size: 16px;
   }
   .content h5{
     font-size: 7px;
     margin-top: 10px !important;
   }
 
 
}

/* slider end */


/* our servicce */

.img-service{
  width: 250px;
  height: 190px;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.img-service:hover{
  transform: scale(1.08);
}


/* content */

.contenthead{
  width: 70%;
  margin: 0 auto;
}
.contentsub{
  text-align: center;
    color: #0C0C0C;
    font-family:  'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 400;
}


@media only screen and (max-width: 991px) {
  
.contenthead{
  width: 100%;
  margin: 0 auto;
}
}


.bg_img{
  background-image: url('../images/container-operation-port-series.jpg');
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.747);
}

.content_h h2{
color: white;
font-size: 31px;
font-weight: 800;
line-height: 38px;
}
.content_h h4{
  color: #faa61a;
}
.content_h h5{
  color: #faa61a;
}
.content_h h6{
  color: white;
  font-weight: 800;
}

.img_sercice{
  background-color: white;
  border-radius: 9px;
  padding: 13px;
}
.img_sercice img{
  transform: scale(1);
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}
.img_sercice img:hover{
  transform: scale(1.05);
}


/* why chose us */

.why img{
  width: 100px;
  height: 100px;
}

.why_contet{
  text-align: center;
    color: #0C0C0C;
    font-family:  'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.why h2{
  font-weight: 700;
  border-bottom: 1px solid #faa61a;
  padding-bottom: 20px;
}
.why p{
  text-align: center;
  color: #000000;
  font-family: "Verdana", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 2px;
}



/* contact page */

.contact_header{
  background-color: black;
  color: aliceblue;
  font-size: 2.5rem;
  padding: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contact_text{
  margin-left: 4rem;
}

.this_row{
  padding: 2rem;
  gap: 4rem;
  display: flex;
  justify-content: space-around;
  
}

.first_col{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}
.second-col{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


/* about */

.ab_bg{
background-color: #353535;
padding: 20px;
}

.orderstart {
  border-right: 3px solid #faa61a;
}


/* gallery */

.color{

}

.imghover img{
  
  padding: 0px;
  border: 5px solid transparent;
  transition: all 0.2s ease-in-out;
}

.img_b{
  /* padding: 10px; */
  margin-right: 10px;
  
}
.imghover{
  padding: 7px;
  background-color: white;
}

.imghover:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
}

.img-b img{
  height: 200px;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .img-b img{
    height: 250px;
    width: 100%;
    object-fit: cover;
  }
}