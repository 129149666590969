/* header */

  .quaheader_design:hover{
  background-color: white !important;
  z-index: 40 !important;
  }
  header {
    padding: 10px;
   font-family:  "Playfair Display", serif !important;
  }
  
  #headermaincss nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    /* cursor: pointer !important; */
  }
  
  #headermaincss nav ul li {
    display:inline-block;
     position: relative;
     cursor: pointer !important;
  }
  
  #headermaincss nav ul li a {
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    display: block;
    
    
  }
  
  #headermaincss nav ul li:hover > ul {
    display: block;
    border-top: 2px solid black;
    left: 0;
    border-radius: 0px;
  }
  
  #headermaincss .dropdown-menu {
    position: absolute;
    background-color: #ffffff;
    top: 100%;
  }
  
  #headermaincss .dropdown-menu li {
    display: block;
    width: 200px;
    border-left: 5px solid transparent;
    transition: all 0.3s ease-in-out;
    
  }
  
  #headermaincss .dropdown-menu li a {
    padding: 10px 12px;
   
    text-decoration: none;
  
  }
  
  #headermaincss .dropdown-menu li:hover {
    background-color: #e0e0e0;
    border-left: 5px solid #353535;
  
  }
  
  #headermaincss .dropdown {
    position: relative;
  }
  
  #headermaincss .dropdown-menu .dropdown-menu {
    left: 100%;
    top: 0;
  }
  #headermaincss .toggle-menu {
      display: none;
      text-align: right;
      cursor: pointer;
      color: #fff;
      padding: 10px 20px;
    }
  @media screen and (max-width: 991px) {
    header{
      padding-top: 0;
    }
    #headermaincss nav ul {
      text-align: center;
      display: none; 
    }
  
    #headermaincss nav ul.open {
      display: block;
    }
  
    #headermaincss nav ul li {
      display: block;
      margin-bottom: 10px;
    }
  
    #headermaincss .dropdown-menu {
      position: static;
      display: none;
      background-color: transparent;
    }
  
    #headermaincss .dropdown-menu li {
      display: block;
    }
  
    #headermaincss .dropdown-menu li:hover {
      background-color: transparent;
    }
  
    #headermaincss .toggle-menu {
      display: block;
      text-align: right;
      cursor: pointer;
      color: #fff;
      padding: 10px 20px;
    }
    .slider_imageset{
      margin-top: -66px !important;
    }
  }
  @media screen and (max-width: 700px){
   .slider_imageset{
      margin-top: -59px !important;
    }
  }
  @media screen and (max-width: 500px){
    .slider_imageset{
      margin-top: -55px !important;
    }
  }
  @media screen and (max-width: 995px){
    header{
      padding-top: 0 !important;
    }
  }
  @media screen and (max-width: 300px){
    .slider_imageset{
      margin-top: -51px !important;
    }
  }
  @media screen and (max-width: 400px){
    .slider_imageset{
      margin-top: -53px !important;
    }
  }
  #headermaincss .offcanvasdesign{
  text-decoration: none !important;
  color: gray;
  }
  #headermaincss .offcanvasdesign:hover{
  color: black;
  }
 .searchinput_focus:focus {
    outline: none;
    border: none;
  }
  .searchmodal_designs .modal-dialog {
  width: 100%;
  max-width: 100%;
  margin: 0;
  }
  #headermaincss .hoverheader_menu{
  border-bottom: 2px solid transparent;
  }
  #headermaincss .hoverheader_menu:hover{
  border-bottom: 2px solid black;
  }
   .slider_imageset{
  margin-top: -67px;
  }
  

  .accordion-flush>.accordion-item>.accordion-header .accordion-button {
    background-color: white; 
  }
  .accordion-button:focus{
    display: flex;
    align-items: center;
    align-content: center;
    box-shadow: none !important;
  }











  /* footer section */
  .footer {
    background-color: #353535;
    padding-top: 80px;
    padding-bottom: 47px;
  }
  
  .footerlinks {
    padding-left: 50px;
  }
  
  .footer .footerlinks h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    padding-bottom: 9px;
    padding-left: 26px;
 
  }
  
  .footermenu li {
    padding-bottom: 9px;
    list-style: none;
  }
  
  .footermenu li:last-child {
    padding-bottom: 0px;
  }
  
  .footermenu li a {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  
    text-transform: capitalize;
    color: #ffffff;
  }
  
  .sociallinks {
    display: flex;
    width: 150px;
    justify-content: space-between;
  }
  
  .sociallinks > li {
    width: 28px;
  }
  
  .footerlogo {
    padding-bottom: 30px;
    text-align: center;
  }
  
  .footerlogo p {
    padding-top: 30px;
    color: #ffffff;
  }
  
  /* footer section end */

  @media (max-width: 990px) {
    .footerlinks {
      padding-left: 2px;
  }
  }

  @media (max-width: 1399px) {
     /* footer resposive */
     .content {
      padding-bottom: 0px;
  }

  .footerlinks {
      padding-left: 23px;
  }

  .footermenu li a {
      font-size: 14px;
  }

  .fotterend {
      font-size: 15px;
  }

  }

  @media (min-width: 1600px) {
    .container {
        max-width: 1600px;
    }

    nav.navbar.navbar-expand-lg .container .navbar-brand img,
    .footerlogo a img {
        max-width: 293px;
    }
  }

  .fotterend {
    padding: 0 20px !important ;
      text-align: center;
      /* height: auto !important; */
    /* line-height: normal !important; */
      font-family: "Roboto", sans-serif;
      /* font-size: 18px; */
      color: #353535;
      background-color: #faa61a;
    }
    .fotterend p{
      background-color: #faa61a;
      padding-top: 15px;
    }
    
    .fotterend p a {
      color: #353535;
    }
    @media (max-width: 575px) {

    }

    .sclmd a {
      width: 45px;
      height: 45px;
      padding: 10px;
      background-color: #ffffff1a;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      background-color: var(--color--);
      border: 2px solid var(--color--);
    }
    .sclmd i {
      font-size: 25px;
      color: var(--color--);
      transition: all 0.3s ease-in-out;
      color: whitesmoke;
    }
    .sclmd a:hover {
      background-color: transparent;
    }
    .sclmd a:hover i {
      color: #faa61a;
    }

    .poppins-thin {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-style: normal;
    }

    .teko {
      font-family: "Teko", sans-serif;
     font-weight: 300;
      font-style: normal;
    }
    .teko li {
      font-family: "Teko", sans-serif;
     font-weight: 300;
      font-style: normal;
    }