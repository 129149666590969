.bg_Precision{
    background-image: url('../images//precision_main.jpg');
    height: 60vh;
    width: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.447);
}

.content_precision p{
    color: #000000;
    font-family: "Nunito", Sans-serif;
    font-size: 18px;
    font-weight: 400;
}
.bor{
    border-bottom: 4px solid #faa61a;
    
}

.text h4{
    color: #faa61a;
    font-weight: 700;
}


/* laser */

.bg_laser{
    background-image: url('../images/laserbg.jpg');
    height: 60vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.594);
}

/* stamping */
.bg_stamping{
    background-image: url('../images/stamping\ bg.jpg');
    height: 70vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.594);
}


/* Metal fabrication */
.bg_metal{
    background-image: url('../images/metal\ fabriction\ bg.jpg  ');
    height: 70vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.594);
}
/* Casing*/
.bg_casting{
    background-image: url('../images//casting\ bg.jpg ');
    height: 70vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.594);
}


/* finishing */

.main_finish{
    background-color: #f7f7f7;
}

.mainbg{
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.tabs button{
    /* border: 2px solid #faa61a !important; */
    margin: 0 10px;
    font-weight: 500;
    color: black !important;
  }
  .tabs .nav-item{
    padding: 10px 0px;
  }
  .tabs .nav-pills .nav-link.active{
    color: black !important;
    background-color: #f7f7f7 !important;
    border-bottom: 2px solid #faa61a;
  }
  .tabs .nav-pills .nav-link:hover{
    color: black !important;
    background-color: #f7f7f7 !important;
    border-bottom: 2px solid #faa61a;
  }


  /* accordiaan */

  .acc .accordion-button:hover {
    background-color: #faa61a !important;
  }


 .acc .accordion-flush>.accordion-item>.accordion-header .accordion-button, .accordion-flush>.accordion-item>.accordion-header .accordion-button.collapsed.active {
  color: #faa61a !important;
  }