.borderbotoom{
    border-bottom:3px solid #faa61a ;
    width: fit-content;
    padding: 10px;
}


.bg_aerospace{
    background-image: url('../images/aerospace.jpg');
    height: 60vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.594);
}

/* automobile */
.bg_automobile{
    background-image: url('../images/automobile\ head.avif');
    height: 60vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.594);
}

/* medical */

.bg_medical{
    background-image: url('../images/medical.jpg');
    height: 60vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.594);
}
/* oil gas */

.bg_oil{
    background-image: url('../images/oil-gas.jpg');
    height: 60vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.594);
}